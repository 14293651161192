import { SET_PRODUTOS,SET_LOADING,SET_PAGE } from '../actions/actionTypes'

const initialState = {
    produtos: null,
    loading: false,
    page: 1
}

export const produtosReducers = ( state = initialState, action ) => {

    switch (action.type) {
        case SET_PRODUTOS:
          return {
            ...state,
            produtos: action.payload
          };
        
        case SET_PAGE:
          return {
            ...state,
            page: action.payload
          };
        
        case SET_LOADING:
          return {
            ...state,
            loading: action.payload
          };
          
        default:
          return state;
      }

}