
import Swal from 'sweetalert2';

export const success = (options) => {
   return Swal.fire({
      icon:'success',
      title: options.title,
      text: options.message,
      html: options.html
   })
}

export const warning = (options) => {
   return Swal.fire({
      icon:'warning',
      title: options.title,
      text: options.message,
      html: options.html
   })
}

export const info = (options) => {
   return Swal.fire({
      icon:'info',
      title: options.title,
      text: options.message,
      html: options.html
   })
}

export const danger = (options) => {
   return Swal.fire({
      icon:'danger',
      title: options.title,
      text: options.message,
      html: options.html
   })
}

export const loading = (action='show',message=null) => {
   if(action === 'show' || (!action && !Swal.isLoading())){
      Swal.fire({
         icon: 'info',
         text: message ? message: 'Por favor, aguarde...',
         showConfirmButton:false,
         showCancelButton: false,
         allowOutsideClick: false,
         allowEscapeKey: false
      })
      Swal.showLoading()
   }else if(action === 'hide' || (!action && Swal.isLoading())){
      Swal.hideLoading()
      Swal.close()
   }
}
